import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface Props {
  name: String
}

const Image: React.FC<Props> = props => {
  const { allFile } = useStaticQuery(graphql`
    query img {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  `)
  const img = allFile.nodes.find((node: any) =>
    node.relativePath.includes(props.name)
  )

  return <Img fluid={img.childImageSharp.fluid} />
}

export default Image
