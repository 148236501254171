import React from "react"
import Fade from "@material-ui/core/Fade"
import Animate from "./Animate"

const AnimatedFade: React.FC = props => {
  const { children } = props
  return (
    <Fade in={true} timeout={{ enter: 1000 }}>
      <Animate>{children}</Animate>
    </Fade>
  )
}

export default AnimatedFade
